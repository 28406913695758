export default {
  finance: {
    cost_record: "费用记录",
    bill_number: "账单编号",
    money: "金额",

    account_balance: "账户余额",
    balance: "余额",
    bill: "账单",
    download_bill_details: "下载账单明细",
    print_bill: "打印账单",
    immediate_payment: "立即支付",

    overdraft_limit: "透支限额",
    expiration_time: "到期时间",
    overdraft_status: "透支状态",
    increase_limit: "提升额度",
    transaction_serial_number: "交易流水号",
    relevant_business_number: "相关业务编号",
    facility_code: "设施代码",
    balance_transaction_details: "余额交易明细",

    recharge_serial_number: "充值流水号",
    recharge_currency: "充值币种",
    recharge_amount: "充值金额",
    payment_currency: "付款币种",
    payment_method: "付款方式",
    exchange_rate: "汇率",
    payment_amount: "付款金额",
    payment_serial_number: "付款流水号",
    recharge_status: "充值状态",
    remarks: "备注",

    business_no: "业务编号",
    business_time: "业务发生时间",
    transaction_type: "交易类型",
    business_type: "业务类型",
    business_country: "业务发生国家",
    happen_currency: "发生币种",
    affiliation_order:"归属账单",
    fee_detail:"费用明细",

    serial_numbers:"流水号",
    serial_number:"序号",
    bill_number: "账单编号",
    business_belonging_cycle:"业务归属周期",
    create_time:"生成日期",
    details:"详细",
    to:"到",
    month_bill: "月账单",
    bill: "账单",

    account_number: "账户号码",
    title_of_account: "账户名称",
    bank_name: "银行名称",
    error_and_again: "出错了，请重试",

    please_select_payment_currency_first: "请先选择付款币种",
    freeze: "冻结",

    create_recharge: "创建充值",
    view_storage_fee_details: "查看仓储费明细",
    view_return_temporary_storage_fee_details: "查看退货暂存费明细",
    export_storage_fee_details: "导出仓储费明细",
    export_return_temporary_storage_fee_details: "导出退货暂存费明细",
    storage_capacity: "存储量",
    storage_time: "存储时间",
  },
};
